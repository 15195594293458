/* eslint-disable react/jsx-no-duplicate-props */ // Temporary until eslint config is updated
import React from 'react';

import { getCurrencyFormat, getCurrencySymbol } from 'wtb-utils';
import { Input, Typography } from 'kill-components';
import { ReactComponent as CheckIcon } from 'assets/check.svg';
import { ReactComponent as EditIcon } from 'assets/edit.svg';
import { useAppContext, useLocaleContext } from 'context';
import useStyles from './DonateDifferentAmount.styles';

const DonateDifferentAmount = () => {
  const { classes } = useStyles();

  const {
    currency,
    minimumAmount,
    isLoading,
    manualDonation,
    setManualDonation,
    isManualDonation,
    setIsManualDonation
  } = useAppContext();
  const { translate } = useLocaleContext();

  const handleDifferentAmountClick = () => {
    if (isLoading) return;

    setIsManualDonation(true);
  };

  const handleAmountChange = (event) => {
    setManualDonation(event.target.value);
  };

  return (
    <>
      {!isManualDonation && (
        <div className={classes.buttonContainer}>
          <button type="button" className={classes.button} onClick={handleDifferentAmountClick}>
            <Typography className={classes.buttonText} variant="caption" color="primary">{translate('donateDifferentAmount')}</Typography>
            <EditIcon className={classes.icon} />
          </button>
        </div>
      )}

      {isManualDonation && (
        <div className={classes.donationContainer}>
          <div className={classes.donation}>
            <div className={classes.header}>
              <Typography fontWeight="bold">{translate('manuallyDonating.title')}</Typography>
              <CheckIcon className={classes.icon} />
            </div>

            <Input
              className={classes.input}
              type="number"
              variant="filled"
              thousandSeparator=" "
              inputProps={{ className: classes.inputContent }}
              InputProps={{
                endAdornment: (
                  <div className={classes.inputCurrency}>
                    <Typography color="textSecondary">{getCurrencySymbol(currency)}</Typography>
                  </div>
                )
              }}
              label={translate('manuallyDonating.inputLabel')}
              helperText={translate('manuallyDonating.minimumAmount', {
                amount: getCurrencyFormat(minimumAmount, currency, null, { decimals: 0 })
              })}
              onChange={handleAmountChange}
              value={manualDonation}
              isClearable={false}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default DonateDifferentAmount;

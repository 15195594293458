import React from 'react';
import clsx from 'clsx';

import { getCurrencyFormat } from 'wtb-utils';
import { Typography } from 'kill-components';
import { ReactComponent as CheckIcon } from 'assets/check.svg';
import { useAppContext } from 'context';
import { DonateDifferentAmount } from 'components';
import useStyles from './Donations.styles';

const Donation = (props) => {
  const { currency } = useAppContext();
  const {
    donation,
    isSelected,
    onClick
  } = props;

  const { classes } = useStyles({ isSelected });

  const {
    title,
    amount,
    subtitle,
    content
  } = donation;

  const onlyAmount = !!amount && !title && !subtitle && !content;
  const hasTitle = !!title;

  const Amount = () => (
    <Typography component="span" fontWeight="bold">{getCurrencyFormat(amount, currency, null, { decimals: 0 })}</Typography>
  );

  return (
    <button
      type="button"
      className={clsx(classes.donation, { [classes.selected]: isSelected })}
      onClick={onClick}
    >
      {!onlyAmount && (
        <div className={classes.header}>
          {!hasTitle && <Amount />}
          {hasTitle && <Typography variant="h6">{title}</Typography>}
          {isSelected && <CheckIcon className={classes.selectedIcon} />}
        </div>
      )}

      {onlyAmount && (
        <div className={clsx({ [classes.onlyAmount]: onlyAmount })}>
          <Amount />
          {isSelected && <CheckIcon className={classes.selectedIcon} />}
        </div>
      )}

      {hasTitle && (
        <div className={classes.subheader}><Amount /></div>
      )}

      {subtitle && (
        <Typography
          className={classes.subtitle}
          variant="caption"
          color="textSecondary"
          gutterBottom
        >
          {subtitle}
        </Typography>
      )}

      {content && (
        <Typography variant="body2">
          {content}
        </Typography>
      )}
    </button>
  );
};

const Donations = () => {
  const { classes } = useStyles();

  const {
    donations,
    selectedDonation,
    isLoading,
    isManualDonation,
    setIsManualDonation,
    setSelectedDonation
  } = useAppContext();

  const isSelected = (index) => !isManualDonation && selectedDonation?.index === index;

  const handleDonationClick = (index) => () => {
    if (isLoading || isSelected(index)) return;

    setSelectedDonation({ index, ...donations[index] });
    setIsManualDonation(false);
  };

  return (
    <div className={classes.root}>
      <div className={classes.donations}>
        {donations.map((donation, index) => (
          <Donation
            key={donation.id}
            donation={donation}
            isSelected={isSelected(index)}
            onClick={handleDonationClick(index)}
          />
        ))}
      </div>

      <DonateDifferentAmount />
    </div>
  );
};

export default Donations;

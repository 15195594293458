import { makeStyles } from 'makeStyles';

export default makeStyles()((theme) => ({
  root: {
    display: 'flex',
    border: `1px solid ${theme.palette.border.main}`,
    borderRadius: '20px',

    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  tab: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    minWidth: '200px',
    height: '40px',
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: '20px',

    '&:hover': {
      backgroundColor: ({ isSelected }) => !isSelected && theme.palette.background.hover
    },
    '&:active': {
      backgroundColor: theme.palette.background.active
    }
  },
  selected: {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.secondary.main,

    '& svg': {
      marginRight: '16px',
      fill: theme.palette.primary.main
    }
  }
}));

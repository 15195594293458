import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { locales } from 'locale';

const resources = {};
Object.keys(locales).forEach((locale) => {
  resources[locale] = { translation: locales[locale].translations };
});

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,

    keySeparator: '.',

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;

import { makeStyles } from 'makeStyles';

const inputBorderOverrides = (borderColor) => ({
  '& .MuiInputBase-root:before': { borderColor },
  '& .MuiInputBase-root:not(.Mui-error)': { borderColor },
  '& .MuiInput-underline:not(.Mui-error):hover:before': { borderBottomColor: borderColor },
  '& .MuiInput-underline:not(.Mui-error):after': { borderBottomColor: borderColor },
  '& .MuiFilledInput-underline:not(.Mui-error):hover:before': { borderBottomColor: borderColor },
  '& .MuiFilledInput-underline:not(.Mui-error):after': { borderBottomColor: borderColor },
  '& .MuiOutlinedInput-root:not(.Mui-error)': {
    '&:hover fieldset': { borderColor },
    '&.Mui-focused fieldset': { borderColor }
  },
  '& input': { borderBottomColor: borderColor }
});

export default makeStyles()((theme) => ({
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    height: '40px',
    padding: '0px 16px',
    border: 'none',
    borderRadius: '20px',
    backgroundColor: 'transparent',
    cursor: 'pointer',

    '&:hover': {
      textDecoration: `${theme.palette.primary.main} underline`,
      backgroundColor: theme.palette.background.hover
    },
    '&:active': {
      backgroundColor: theme.palette.background.active
    }
  },
  buttonText: {
    marginRight: '8px'
  },
  icon: {
    fill: theme.palette.primary.main
  },
  donationContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  donation: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    cursor: 'pointer',
    padding: '16px',
    margin: '8px',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '4px',
    backgroundColor: theme.palette.secondary.main,
    maxWidth: '33%',
    marginBottom: '16px',

    [theme.breakpoints.down('sm')]: {
      maxWidth: '50%'
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
      margin: '0px 0px 12px 0px'
    }
  },
  header: {
    display: 'flex',
    width: '100%',
    paddingBottom: '24px',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  selectedIcon: {
    fill: theme.palette.primary.main
  },
  input: {
    ...inputBorderOverrides(theme.palette.primary.main),

    '& .MuiInputBase-root': {
      backgroundColor: 'white'
    }
  },
  inputContent: {
    textAlign: 'right',
    paddingRight: '8px'
  },
  inputCurrency: {
    marginTop: '18px'
  }
}));

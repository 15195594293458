import React, {
  createContext,
  useEffect,
  useContext,
  useState
} from 'react';
import deepmerge from 'deepmerge';

import { CacheProvider } from '@emotion/react';
import { TssCacheProvider } from 'tss-react';
import createCache from '@emotion/cache';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { useMerchantId, useMerchantData } from 'hooks';
import * as themes from 'themes';

const muiCache = createCache({
  key: 'mui',
  prepend: true,
  speedy: false
});

const tssCache = createCache({
  key: 'wtb',
  speedy: false
});

const ThemeContext = createContext();

const defaultState = {
  themeName: 'main',
  themeObject: themes.main
};

const ThemeProvider = ({ children, initialState: state = {} }) => {
  const initialState = deepmerge(defaultState, state);

  const id = useMerchantId();

  const merchantData = useMerchantData();

  const [ themeObject, setThemeObject ] = useState(initialState.themeObject);

  const editTheme = (theme) => {
    setThemeObject((prev) => deepmerge(prev, theme));
  };

  useEffect(() => {
    if (!id || !merchantData?.theme) return;

    setThemeObject(deepmerge(themes.main, merchantData.theme));
  }, [ id, merchantData?.theme ]);

  return (
    <ThemeContext.Provider
      value={{
        theme: themeObject,
        editTheme
      }}
    >
      <CacheProvider value={muiCache}>
        <TssCacheProvider value={tssCache}>
          <MuiThemeProvider theme={themeObject}>
            {children}
          </MuiThemeProvider>
        </TssCacheProvider>
      </CacheProvider>
    </ThemeContext.Provider>
  );
};

const ThemeConsumer = ThemeContext.Consumer;

const useThemeContext = () => {
  const context = useContext(ThemeContext);

  if (context === undefined) {
    throw new Error('useThemeContext must be used within a ThemeProvider');
  }

  return context;
};

export {
  ThemeContext, ThemeProvider, ThemeConsumer, useThemeContext
};

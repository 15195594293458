import { useState } from 'react';

import { differenceInDays, addMonths, format as formatDate } from 'date-fns';
import { Logger } from 'wtb-utils';
import { generateCheckout } from 'api';
import { useAppContext } from 'context';
import { useMerchantId } from 'hooks';

const useGenerateCheckout = () => {
  const { selectedPeriodicity, setIsLoading, merchantData } = useAppContext();

  const [ checkoutData, setCheckoutData ] = useState();
  const [ isPostDataDone, setIsPostDataDone ] = useState(false);

  const id = useMerchantId();

  const getStartDate = () => {
    const { startDay = 28 } = merchantData;

    const now = new Date();

    const sameMonth = new Date(now.getFullYear(), now.getMonth(), startDay);
    const nextMonth = addMonths(sameMonth, 1);

    let result;
    if (differenceInDays(sameMonth, now) >= 2) {
      result = sameMonth;
    } else {
      result = nextMonth;
    }

    return formatDate(result, 'yyyy-MM-dd');
  };

  const generatePaymentDetails = (price, productName, orderId) => {
    const startDate = getStartDate();
    const vatPercentage = merchantData.vatPercentage ? merchantData.vatPercentage : 0;

    // We do it this way because default behavior is subscription
    const isSubscription = !!(selectedPeriodicity?.value !== 'ONE_TIME');

    const details = {
      campaignId: process.env.NODE_ENV === 'production' ? merchantData.prodCampaignId : merchantData.testCampaignId,
      order: {
        id: orderId,
        parts: 0,
        startDate,
        items: [
          {
            article: {
              id: orderId,
              description: productName,
              value: price,
              vatPercentage
            },
            numberOfItems: 1
          }
        ]
      }
    };

    if (isSubscription) {
      details.order.type = 'SUBSCRIPTION';
      details.order.periodicity = selectedPeriodicity?.value || 'MONTHLY';

      // eslint-disable-next-line no-param-reassign
      details.order.items.forEach((item) => { item.recurring = 'ALL'; });
    } else {
      details.order.type = 'PURCHASE';

      // eslint-disable-next-line no-param-reassign
      details.order.items.forEach((item) => { item.recurring = 'FIRST'; });
    }

    return details;
  };

  const _generateCheckout = (amount, productName, orderId) => {
    setIsLoading(true);

    const paymentObject = generatePaymentDetails(amount, productName, orderId);

    generateCheckout(id, paymentObject)
      .then((response) => {
        setCheckoutData((prev) => ({
          ...prev,
          ...response
        }));

        setIsPostDataDone(true);

        window.open(response.checkoutUrl, '_blank', 'noopener,noreferrer');
      })
      .catch((error) => {
        Logger.error(error);
        // eslint-disable-next-line no-alert
        alert('Error creating checkout');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    checkoutData,
    generateCheckout: _generateCheckout,
    isPostDataDone
  };
};

export default useGenerateCheckout;

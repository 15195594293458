import { makeStyles } from 'makeStyles';

export default makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '64px 24px 12px 24px',
    width: '100%',

    [theme.breakpoints.down('xs')]: {
      maxWidth: '540px'
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '680px'
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '840px'
    }
  },
  periodicities: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginBottom: '32px',

    [theme.breakpoints.down('xs')]: {
      minWidth: '100%'
    }
  },
  donations: {
    width: '100%'
  },
  title: {
    marginBottom: '16px'
  },
  donateButton: {
    marginBottom: '24px'
  }
}));

import deepmerge from 'deepmerge';

import main from './main';

const oceanlive = {
  palette: {
    primary: {
      main: '#02B9AC'
    },
    secondary: {
      main: '#E9F8F6'
    }
  }
};

export default deepmerge(main, oceanlive);

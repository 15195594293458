import React, {
  useEffect,
  useState,
  useContext,
  createContext
} from 'react';
import deepmerge from 'deepmerge';

import { useLocaleContext } from 'context';
import { useMerchantData, useMerchantId } from 'hooks';

const AppContext = createContext();

const DEFAULT_SELECTED_PERIODICITY = 0;

const defaultState = {
  periodicities: undefined,
  rawPeriodicities: undefined,
  selectedPeriodicity: undefined,
  startDay: 28,
  currency: undefined,
  vatPercentage: 0,
  donations: undefined,
  selectedDonation: undefined,
  isLoading: true,
  isManualDonation: false,
  defaultAmount: undefined,
  minimumAmount: undefined,
  manualDonation: undefined,
  merchantName: undefined,
  orderPrefix: undefined,
  title: undefined
};

const AppProvider = ({ children, initialState: state = {} }) => {
  const initialState = deepmerge(defaultState, state);

  const id = useMerchantId();

  const { translate } = useLocaleContext();

  const merchantData = useMerchantData();

  const [ periodicities, setPeriodicities ] = useState(initialState.periodicities);
  const [ rawPeriodicities, setRawPeriodicities ] = useState(initialState.rawPeriodicities);
  const [ selectedPeriodicity, setSelectedPeriodicity ] = useState(initialState.selectedPeriodicity);
  const [ startDay, setStartDay ] = useState(initialState.startDay);
  const [ currency, setCurrency ] = useState(initialState.currency);
  const [ vatPercentage, setVatPercentage ] = useState(initialState.vatPercentage);
  const [ donations, setDonations ] = useState(initialState.donations);
  const [ selectedDonation, setSelectedDonation ] = useState(initialState.selectedDonation);
  const [ isManualDonation, setIsManualDonation ] = useState(initialState.isManualDonation);
  const [ defaultAmount, setDefaultAmount ] = useState(initialState.defaultAmount);
  const [ minimumAmount, setMinimumAmout ] = useState(initialState.minimumAmount);
  const [ manualDonation, setManualDonation ] = useState(initialState.manualDonation);
  const [ merchantName, setMerchantName ] = useState(initialState.merchantName);
  const [ productName, setProductName ] = useState(initialState.productName);
  const [ title, setTitle ] = useState(initialState.title);

  const [ isLoading, setIsLoading ] = useState(initialState.isLoading);

  useEffect(() => {
    if (!merchantData) return;

    document.querySelector('#favicon').href = `${process.env.REACT_APP_API_URL}/${id}/favicon`;
    document.querySelector('#merchant-name').innerHTML = merchantData.merchantName;

    const _periodicities = merchantData.periodicities || [ 'ONE_TIME' ];
    const _donations = merchantData.donations?.map((donation) => ({ ...donation, id: Math.random() })) || [];

    setRawPeriodicities(_periodicities);
    setStartDay(merchantData.startDay);
    setCurrency(merchantData.currency);
    setVatPercentage(merchantData.vatPercentage);
    setDonations(_donations);
    setDefaultAmount(merchantData.defaultAmount);
    setMinimumAmout(merchantData.minimumAmount);
    setMerchantName(merchantData.merchantName);
    setTitle(merchantData.title);
    setProductName(merchantData.productName);
    setProductName(merchantData.productName);

    // Added to avoid wrong theme flashing
    setTimeout(() => setIsLoading(false), 1000);
  }, [
    id,
    merchantData,
    setCurrency,
    setDefaultAmount,
    setDonations,
    setMinimumAmout,
    setPeriodicities,
    translate
  ]);

  useEffect(() => {
    if (rawPeriodicities?.length) {
      setPeriodicities(rawPeriodicities.map((periodicity) => ({
        label: translate(`periodicities.${periodicity.toLowerCase()}`),
        value: periodicity
      })));
    }
  }, [ rawPeriodicities, translate ]);

  useEffect(() => {
    if (!periodicities || !periodicities.length) return;

    setSelectedPeriodicity({
      ...periodicities[DEFAULT_SELECTED_PERIODICITY],
      index: DEFAULT_SELECTED_PERIODICITY
    });
  }, [ periodicities ]);

  useEffect(() => {
    if (!donations || !donations.length) return;

    const selected = Math.floor(donations.length / 2);

    setSelectedDonation({
      ...donations[selected],
      index: selected
    });
  }, [ donations ]);

  useEffect(() => {
    if (defaultAmount) {
      setManualDonation(defaultAmount);
    }
  }, [ defaultAmount ]);

  return (
    <AppContext.Provider
      value={{
        periodicities, setPeriodicities,
        rawPeriodicities, setRawPeriodicities,
        selectedPeriodicity, setSelectedPeriodicity,
        startDay, setStartDay,
        currency, setCurrency,
        vatPercentage, setVatPercentage,
        donations, setDonations,
        selectedDonation, setSelectedDonation,
        isLoading, setIsLoading,
        isManualDonation, setIsManualDonation,
        defaultAmount, setDefaultAmount,
        minimumAmount, setMinimumAmout,
        manualDonation, setManualDonation,
        merchantName, setMerchantName,
        title, setTitle,
        productName, setProductName,
        merchantData
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const AppConsumer = AppContext.Consumer;

const useAppContext = () => {
  const context = useContext(AppContext);

  if (context === undefined) {
    throw new Error('useAppContext must be used within a AppProvider');
  }

  return context;
};

export {
  AppContext, AppProvider, AppConsumer, useAppContext
};

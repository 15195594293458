import { useEffect, useRef, useState } from 'react';

import { Logger } from 'wtb-utils';
import { getMerchantData } from 'api';
import { useMerchantId } from 'hooks';

const useMerchantData = () => {
  const cache = useRef({});
  const status = useRef('EMPTY');

  const [ merchantData, setMerchantData ] = useState();

  const id = useMerchantId();

  useEffect(() => {
    if (!id || status.current === 'FETCHING') return;

    if (cache.current[id]) {
      setMerchantData(cache.current[id]);
    } else {
      status.current = 'FETCHING';

      getMerchantData(id)
        .then((data) => {
          cache.current[id] = data;
          setMerchantData(data);
          status.current = 'FETCHED';
        })
        .catch((error) => {
          Logger.error(error);
          // eslint-disable-next-line no-alert
          alert('Error getting data');
        });
    }
  }, [ id ]);

  return merchantData;
};

export default useMerchantData;

import deepmerge from 'deepmerge';

import main from './main';

const psoriasisforbundet = {
  palette: {
    primary: {
      main: '#0072A7'
    },
    secondary: {
      main: '#ECEBE9'
    }
  }
};

export default deepmerge(main, psoriasisforbundet);

import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route } from 'react-router-dom';

import { AppProvider, LocaleProvider, ThemeProvider } from 'context';
import { Builder } from 'components';
import App from './App';
import i18n from './i18n';
import reportWebVitals from './reportWebVitals';
import './index.css';

// eslint-disable-next-line no-unused-vars
const l = i18n.language;

const root = createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Route
      path="/:id?"
      render={() => (
        <ThemeProvider>
          <LocaleProvider>
            <AppProvider>
              <App />

              <Route exact path="/:id/builder" component={Builder} />
            </AppProvider>
          </LocaleProvider>
        </ThemeProvider>
      )}
    />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import deepmerge from 'deepmerge';

import main from './main';

const actionaid = {
  palette: {
    primary: {
      main: '#ED0506'
    },
    secondary: {
      main: '#FEEBEB'
    }
  }
};

export default deepmerge(main, actionaid);

import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  createContext
} from 'react';
import deepmerge from 'deepmerge';
import { useTranslation } from 'react-i18next';

import {
  getCookie,
  setCookie,
  Logger
} from 'wtb-utils';
import { locales, defaultLocale } from 'locale';

const LocaleContext = createContext();

const languages = Object.keys(locales);
const localeExists = (locale) => (!!locales[locale]);

const defaultState = {
  locale: undefined
};

const LocaleProvider = ({ children, initialState: state = {} }) => {
  const initialState = deepmerge(defaultState, state);

  const { i18n, t: translate } = useTranslation();

  const [ currentLocale, setCurrentLocale ] = useState(initialState.locale);

  const changeLocale = useCallback((locale) => {
    if (currentLocale === locale) return;

    i18n.changeLanguage(locale);
    setCurrentLocale(locale);
    setCookie('locale', `${locale}-x-charity`, { domain: process.env.REACT_APP_LOCALE_COOKIE_DOMAIN, path: '/' });
  }, [ currentLocale, i18n ]);

  const setDefaultLocale = useCallback(() => {
    const cookieLanguage = getCookie('locale');
    const systemLanguage = window.navigator.language?.split('-')[0];

    if (cookieLanguage?.value && localeExists(cookieLanguage.value)) {
      changeLocale(cookieLanguage.value);
    } else if (localeExists(systemLanguage)) {
      changeLocale(systemLanguage);
    } else if (localeExists(defaultLocale)) {
      changeLocale(defaultLocale);
    } else {
      Logger.warn('No default language could be set.');
    }
  }, [ changeLocale ]);

  useEffect(() => {
    if (currentLocale) return;

    const cookieLanguage = getCookie('locale');

    if (cookieLanguage?.value && localeExists(cookieLanguage.value)) {
      changeLocale(cookieLanguage.value);
    } else {
      setDefaultLocale();
    }
  }, [
    currentLocale,
    changeLocale,
    setDefaultLocale
  ]);

  return (
    <LocaleContext.Provider
      value={{
        locale: currentLocale,
        locales: languages,
        setDefaultLocale,
        changeLocale,
        translate
      }}
    >
      {children}
    </LocaleContext.Provider>
  );
};

const LocaleConsumer = LocaleContext.Consumer;

const useLocaleContext = () => {
  const context = useContext(LocaleContext);

  if (context === undefined) {
    throw new Error('useLocaleContext must be used within a LocaleProvider');
  }

  return context;
};

export {
  LocaleContext, LocaleProvider, LocaleConsumer, useLocaleContext
};

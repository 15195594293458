import React from 'react';
import clsx from 'clsx';

import { Typography } from 'kill-components';
import { ReactComponent as CheckIcon } from 'assets/check.svg';
import { useAppContext } from 'context';
import useStyles from './Tabs.styles';

const Tabs = (props) => {
  const {
    items,
    selectedIndex,
    onChange
  } = props;

  const isSelected = (index) => selectedIndex === index;

  const { classes } = useStyles({ isSelected });

  const { isLoading } = useAppContext();

  const handleClick = (index) => () => {
    if (isLoading || isSelected(index)) return;

    onChange({ index, ...items[index] });
  };

  return (
    <span className={classes.root}>
      {items.map((item, index) => (
        <button
          className={clsx(classes.tab, { [classes.selected]: isSelected(index) })}
          key={item.label || index}
          type="button"
          onClick={handleClick(index)}
        >
          {isSelected(index) && <CheckIcon />}
          <Typography fontWeight={isSelected(index) ? 'bold' : 'medium'}>{item.label}</Typography>
        </button>
      ))}
    </span>
  );
};

export default Tabs;

/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { ChromePicker } from 'react-color';

import {
  Checkbox,
  Input,
  Select,
  Typography
} from 'kill-components';
import { Button } from 'components';
import { useAppContext, useThemeContext } from 'context';
import useStyles from './Builder.styles';

const ColorInput = ({ label, type }) => {
  const { classes } = useStyles();

  const { theme, editTheme } = useThemeContext();

  const split = type.split('.');
  const color = split[0];
  const shade = split[1];

  const handleColorChange = (_color) => {
    editTheme({ palette: { [color]: { [shade]: _color.hex.toUpperCase() } } });
  };

  return (
    <div className={classes.colorContainer}>
      <Typography variant="subtitle2" gutterBottom>{label}</Typography>
      <ChromePicker
        color={theme.palette[color][shade]}
        onChangeComplete={handleColorChange}
      />
    </div>
  );
};

const Donations = () => {
  const { classes } = useStyles();

  const {
    currency,
    donations, setDonations,
    minimumAmount, setMinimumAmout,
    defaultAmount, setDefaultAmount
  } = useAppContext();

  const handleDonationChange = (index, key) => (e) => {
    setDonations((prev) => {
      const result = [ ...prev ];

      result[index][key] = e.target.value;

      return result;
    });
  };

  const addNewDonation = () => {
    // If there's already some donation, copy the last one. Otherwise, create one with default values
    setDonations((prev) => {
      if (prev?.length) {
        return [ ...prev, { ...prev[prev.length - 1], id: Math.random() } ];
      } else {
        return [ { amount: 50, currency, id: Math.random() } ];
      }
    });
  };

  const removeDonation = (index) => {
    setDonations((prev) => prev.reduce((acc, curr, i) => {
      if (i !== index) {
        return [ ...acc, curr ];
      } else {
        return acc;
      }
    }, []));
  };

  return (
    <div className={classes.container}>
      <Typography className={classes.title} variant="h6">Donations</Typography>

      {donations?.map((donation, index) => (
        <div id={donation.id} key={donation.id} className={classes.donationContainer}>
          {donations.length > 1 && (
            <Button className={classes.removeDonationButton} floating onClick={() => removeDonation(index)}>X</Button>
          )}
          <div style={{ display: 'flex' }}>
            <Input
              className={classes.donationAmount}
              type="number"
              label="Amount"
              value={donation.amount || 0}
              onChange={handleDonationChange(index, 'amount')}
              isClearable={false}
            />
            <Input
              className={classes.donationSubtitle}
              type="text"
              label="Title (optional)"
              value={donation.title || ''}
              onChange={handleDonationChange(index, 'title')}
            />
            <Input
              className={classes.donationSubtitle}
              type="text"
              label="Subtitle (optional)"
              value={donation.subtitle || ''}
              onChange={handleDonationChange(index, 'subtitle')}
            />
          </div>

          <Input
            className={classes.donationContent}
            type="text"
            label="Content (optional)"
            multiline
            value={donation.content || ''}
            onChange={handleDonationChange(index, 'content')}
          />
        </div>
      ))}

      {donations?.length < 3 && (
        <button className={classes.newDonationButton} onClick={addNewDonation} type="button">Add new donation</button>
      )}

      <Typography className={classes.title} variant="h6">Manual donation</Typography>

      <div style={{ display: 'flex' }}>
        <Input
          className={classes.donationMinimumAmount}
          type="number"
          label="Minimum amount"
          value={minimumAmount || 0}
          onChange={(e) => setMinimumAmout(e.target.value)}
          isClearable={false}
        />
        <Input
          className={classes.donationMinimumAmount}
          type="number"
          label="Default amount (optional)"
          value={defaultAmount || 0}
          onChange={(e) => setDefaultAmount(e.target.value)}
        />
      </div>
    </div>
  );
};

const Builder = () => {
  const { classes } = useStyles();

  const {
    merchantName, setMerchantName,
    rawPeriodicities, setRawPeriodicities,
    startDay, setStartDay,
    currency, setCurrency,
    vatPercentage, setVatPercentage,
    productName, setProductName,
    donations,
    minimumAmount,
    defaultAmount,
    title, setTitle,
    merchantData
  } = useAppContext();
  const { theme } = useThemeContext();

  const [ orderPrefix, setOrderPrefix ] = useState(merchantData?.orderPrefix);
  const [ campaignIdProd, setCampaignIdProd ] = useState(merchantData?.prodCampaignId);
  const [ campaignIdTest, setCampaignIdTest ] = useState(merchantData?.testCampaignId);

  useEffect(() => {
    if (merchantData) {
      setOrderPrefix(merchantData.orderPrefix);
      setCampaignIdProd(merchantData.prodCampaignId);
      setCampaignIdTest(merchantData.testCampaignId);

      // Give some time to merchantData to fill the base state then add our defaults in case they are not in the file
      setTimeout(() => {
        if (!merchantData.currency) {
          setCurrency('SEK');
        }
      }, 500);
    }
  }, [ merchantData, setCurrency ]);

  const onPeriodicitiesChange = (e) => {
    setRawPeriodicities(
      rawPeriodicities?.includes(e.target.value)
        ? rawPeriodicities.filter((el) => el !== e.target.value)
        : [ ...rawPeriodicities, e.target.value ]
    );
  };

  const handleSubmit = () => {
    const _theme = {
      palette: {
        primary: {
          main: theme.palette.primary.main
        },
        secondary: {
          main: theme.palette.secondary.main
        }
      }
    };
    const config = {
      merchantName,
      orderPrefix,
      productName,
      periodicities: rawPeriodicities,
      startDay,
      currency,
      vatPercentage,
      minimumAmount,
      defaultAmount,
      title,
      donations
    };

    const toDelete = [];
    Object.entries(config).forEach(([ key, value ]) => {
      // Remove null, undefined and empty strings, but not 0
      if ((!value || value === '') && value !== 0) {
        toDelete.push(key);
      }
    });
    toDelete.forEach((key) => { delete config[key]; });

    // eslint-disable-next-line no-param-reassign
    config.donations.forEach((donation) => { delete donation.id; });

    const result = {
      theme: _theme,
      ...config,
      testCampaignId: campaignIdTest,
      prodCampaignId: campaignIdProd
    };

    console.log(JSON.stringify(result, null, 2));
  };

  return (
    <div className={classes.root}>
      <div>
        <Typography variant="h4">Theme</Typography>
        <div className={classes.container}>
          {/* <div className={classes.title}>Primary</div> */}

          <div className={classes.colorsContainer}>
            <ColorInput label="Primary" type="primary.main" />
            <ColorInput label="Primary Light" type="secondary.main" />
          </div>
        </div>

        {/*
        <div className={classes.container}>
          <div className={classes.title}>Text</div>

          <ColorInput type="Text Primary" />
          <ColorInput type="Text Primary Light" />
          <ColorInput type="Text Secondary" />
        </div>

        <div className={classes.container}>
          <div className={classes.title}>Containers</div>

          <ColorInput type="Border" />
        </div>

        <div className={classes.container}>
          <div className={classes.title}>Effects</div>

          <ColorInput type="Hover" />
        </div>
        */}
      </div>

      <div>
        <Typography variant="h4">Config</Typography>

        <div className={classes.container}>
          <Typography className={classes.title} variant="h6">Merchant</Typography>
          <div style={{ display: 'flex' }}>
            <Input
              className={classes.merchantField}
              type="text"
              label="Merchant name"
              value={merchantName || ''}
              onChange={(e) => setMerchantName(e.target.value)}
              isClearable={false}
            />
            <Input
              className={classes.merchantField}
              type="text"
              label="Order prefix (internal)"
              value={orderPrefix || ''}
              onChange={(e) => setOrderPrefix(e.target.value)}
              isClearable={false}
            />
          </div>
          <Input
            className={classes.productName}
            type="text"
            label="Product name (internal)(optional)"
            value={productName || ''}
            onChange={(e) => setProductName(e.target.value)}
          />
          <div style={{ display: 'flex' }}>
            <Input
              type="text"
              label="Campaign ID TEST (internal)"
              value={campaignIdTest || ''}
              onChange={(e) => setCampaignIdTest(e.target.value)}
            />
            <Input
              type="text"
              label="Campaign ID PROD (internal)"
              value={campaignIdProd || ''}
              onChange={(e) => setCampaignIdProd(e.target.value)}
            />
          </div>
        </div>

        <div className={classes.container}>
          <Typography className={classes.title} variant="h6">Periodicities</Typography>

          <div className={classes.periodicities}>
            <Checkbox
              label="One time"
              value="ONE_TIME"
              checked={!!(rawPeriodicities?.includes('ONE_TIME'))}
              onChange={onPeriodicitiesChange}
            />
            <Checkbox
              label="Monthly"
              value="MONTHLY"
              checked={!!(rawPeriodicities?.includes('MONTHLY'))}
              onChange={onPeriodicitiesChange}
            />
          </div>

          <Input
            className={classes.startDay}
            type="number"
            label="Start Day"
            value={startDay || 28}
            onChange={(e) => setStartDay(e.target.value)}
          />
        </div>

        <div className={classes.container}>
          <Typography className={classes.title} variant="h6">Title</Typography>
          <Input
            className={classes.donationSubtitle}
            type="text"
            label="Page title (optional)"
            value={title || ''}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>

        <div className={classes.container}>
          <Typography className={classes.title} variant="h6">Currency</Typography>
          <Select
            className={classes.currency}
            label="Currency"
            items={[
              { text: 'SEK - kr', value: 'SEK' },
              { text: 'NOK - kr', value: 'NOK' },
              { text: 'EUR - €', value: 'EUR' }
            ]}
            value={currency}
            size="large"
            onChange={(e) => setCurrency(e.target.value)}
          />

          <Input
            className={classes.vatPercentage}
            type="number"
            label="VAT Percentage"
            value={vatPercentage || 0}
            onChange={(e) => setVatPercentage(e.target.value)}
          />
        </div>

        <Donations />
      </div>

      <div className={classes.buttonsContainer}>
        <Button className={classes.button} onClick={handleSubmit}>Export to console</Button>
      </div>
    </div>
  );
};

export default Builder;

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const useMerchantId = () => {
  const [ id, setId ] = useState();

  const { id: paramsId } = useParams();

  useEffect(() => {
    if (id) return;

    let subdomainId = window.location.host.split('.')[0];

    // If subdomain is in test, take out the last 'test' occurence in the name
    if (subdomainId.endsWith('test') && subdomainId.length > 4) {
      subdomainId = subdomainId.slice(0, subdomainId.length - 4);
    }

    setId(paramsId || subdomainId);
  }, [ id, paramsId ]);

  return id;
};

export default useMerchantId;

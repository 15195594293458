import { makeStyles } from 'makeStyles';

export default makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '32px'
  },
  donations: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  donation: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    cursor: 'pointer',
    padding: '16px',
    margin: '8px',
    border: `1px solid ${theme.palette.border.main}`,
    borderRadius: '4px',
    backgroundColor: 'transparent',
    maxWidth: '33%',
    marginBottom: '16px',

    '&:hover': {
      backgroundColor: ({ isSelected }) => !isSelected && theme.palette.background.hover
    },
    '&:active': {
      backgroundColor: theme.palette.background.active
    },

    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
      maxWidth: '100%',
      margin: '0px 0px 12px 0px'
    }
  },
  header: {
    display: 'flex',
    width: '100%',
    paddingBottom: '16px',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  subheader: {
    marginBottom: '16px'
  },
  selected: {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main
  },
  selectedIcon: {
    fill: theme.palette.primary.main
  },
  onlyAmount: {
    position: 'relative',
    width: '100%',

    '& span': {
      textAlign: 'center'
    },
    '& svg': {
      position: 'absolute',
      top: 0,
      right: 0
    }
  }
}));

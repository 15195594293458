import deepmerge from 'deepmerge';

import main from './main';

const lakareutangranser = {
  palette: {
    primary: {
      main: '#E01819'
    },
    secondary: {
      main: '#E1E1E1'
    }
  }
};

export default deepmerge(main, lakareutangranser);

import React from 'react';
import clsx from 'clsx';

import { Typography } from 'kill-components';
import { ReactComponent as LoadingIcon } from 'assets/loading.svg';
import useStyles from './Button.styles';

const Button = (props) => {
  const {
    className,
    children,
    onClick,
    isLoading,
    disabled
  } = props;

  const { classes } = useStyles({ isLoading });

  return (
    <button
      type="button"
      className={clsx(classes.root, { [classes.isLoading]: isLoading }, className)}
      onClick={onClick}
      disabled={disabled || isLoading}
    >
      {isLoading && <LoadingIcon className={classes.loadingIcon} />}
      <Typography component="span" variant="body2" fontWeight="bold">{children}</Typography>
    </button>
  );
};

export default Button;

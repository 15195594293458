import React from 'react';

import { Typography } from 'kill-components';
import { ReactComponent as WaytobillLogo } from 'assets/waytobill-logo.svg';
import { useAppContext, useLocaleContext } from 'context';
import useStyles from './Footer.styles';

const Footer = () => {
  const { classes } = useStyles();

  const { merchantName } = useAppContext();
  const { translate } = useLocaleContext();

  return (
    <div className={classes.root}>
      <Typography
        className={classes.title}
        variant="caption"
        color="textSecondary"
        align="center"
      >
        {translate('footer', { merchant: merchantName })}
      </Typography>
      <WaytobillLogo />
    </div>
  );
};

export default Footer;

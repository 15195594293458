import sv from './locale_sv';

const defaultLocale = 'sv';

const locales = {
  sv: {
    name: 'Svenska',
    translations: sv
  }
};

const languages = Object.keys(locales);

export {
  defaultLocale,
  locales,
  languages
};

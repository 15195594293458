import deepmerge from 'deepmerge';

import main from './main';

const miljopartiet = {
  palette: {
    primary: {
      main: '#53A045'
    },
    secondary: {
      main: '#53A045'
    }
  }
};

export default deepmerge(main, miljopartiet);

import tinycolor from 'tinycolor2';
import { makeStyles } from 'makeStyles';

export default makeStyles()((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    height: '48px',
    maxWidth: '50%',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    borderRadius: '20px',
    border: 'none',
    padding: '0px 16px',

    '&:hover': {
      backgroundColor: ({ isLoading }) => !isLoading && tinycolor(theme.palette.primary.main).darken(4)
    },
    '&:active': {
      backgroundColor: ({ isLoading }) => !isLoading && tinycolor(theme.palette.primary.main).darken(10)
    },

    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
      maxWidth: '100%'
    }
  },
  isLoading: {
    cursor: 'default',
    backgroundColor: theme.palette.secondary.main,

    '& span': {
      color: theme.palette.primary.main,
      opacity: 0.38
    }
  },
  '@keyframes rotation': {
    '0%': {
      transform: 'rotate(0deg)'
    },
    '100%': {
      transform: 'rotate(359deg)'
    }
  },
  loadingIcon: {
    marginRight: '12px',
    fill: theme.palette.primary.main,
    animation: '$rotation 0.8s linear infinite'
  }
}));

import React from 'react';

import { Logger } from 'wtb-utils';
import { Loading, Typography } from 'kill-components';
import { useAppContext, useLocaleContext } from 'context';
import {
  Button,
  Donations,
  Footer,
  Tabs
} from 'components';
import { useGenerateCheckout } from 'hooks';
import useStyles from 'App.styles.js';

const DEFAULT_PRODUCT_NAME = 'Månadsgivande';

const App = () => {
  const { classes } = useStyles();

  const {
    periodicities,
    donations,
    selectedPeriodicity,
    setSelectedPeriodicity,
    selectedDonation,
    manualDonation,
    isManualDonation,
    orderPrefix,
    isLoading,
    setIsLoading,
    title
  } = useAppContext();
  const { translate } = useLocaleContext();

  const { checkoutData, generateCheckout, isPostDataDone } = useGenerateCheckout();

  const getOrderPrefix = (orderId) => orderId || `${orderPrefix || ''}${Date.now().toString()}`;

  const handleSubmit = () => {
    setIsLoading(true);

    const amount = parseInt(isManualDonation ? manualDonation : selectedDonation?.amount, 10);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const product = urlParams.get('ItemDescription');
    const orderId = urlParams.get('OrderId');

    generateCheckout(amount, product || DEFAULT_PRODUCT_NAME, getOrderPrefix(orderId));
  };

  const handleRedirect = () => {
    if (!isPostDataDone) {
      Logger.error('Trying to redirect before having correct data');
      return;
    }

    window.open(checkoutData.checkoutUrl, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className={classes.root}>
      {!isLoading ? (
        <>
          {periodicities && periodicities.length > 1 && (
            <div className={classes.periodicities}>
              <Typography
                className={classes.title}
                variant="caption"
                color="textSecondary"
                align="center"
              >
                {translate('selectPeriodicity')}
              </Typography>

              <Tabs
                selectedIndex={selectedPeriodicity?.index}
                items={periodicities}
                onChange={setSelectedPeriodicity}
              />
            </div>
          )}

          {donations && (
            <div className={classes.donations}>
              <Typography
                className={classes.title}
                color="textSecondary"
                variant="caption"
                component="div"
                align="center"
              >
                {title || translate('selectDonation')}
              </Typography>

              <Donations />
            </div>
          )}

          {!isPostDataDone && (
            <Button
              className={classes.donateButton}
              onClick={handleSubmit}
              isLoading={isLoading}
            >
              {translate('donateNow')}
            </Button>
          )}
          {isPostDataDone && (
            <Button className={classes.donateButton} onClick={handleRedirect}>
              {translate('redirectNow')}
            </Button>
          )}

          <Footer />
        </>
      ) : (
        <Loading backdrop isLoading />
      )}
    </div>
  );
};

export default App;

import { makeStyles } from 'makeStyles';

export default makeStyles()(() => ({
  root: {
    overflowY: 'auto',
    maxHeight: '100vh',
    minWidth: '530px',
    border: '5px solid black',
    borderRadius: '12px 0px 0px 12px',
    boxShadow: '-6px 0px 8px 0px rgba(0,0,0,0.24)',
    backgroundColor: '#E3E3E3',
    padding: '12px',
    boxSizing: 'border-box'
  },
  container: {
    marginBottom: '8px',
    padding: '16px'
  },
  title: {
    display: 'block',
    marginBottom: '20px'
  },

  colorsContainer: {
    display: 'flex'
  },
  colorContainer: {
    marginRight: '36px'
  },

  periodicities: {
    display: 'flex'
  },
  startDay: {
    marginTop: '12px'
  },

  currency: {
    width: '200px',

    '& label': {
      fontSize: '12px',
      lineHeight: '1.33',
      letterSpacing: '0.4px',
      transform: 'translate(15px, -7px) scale(0.9)!important'
    }
  },
  vatPercentage: {
    marginLeft: '8px'
  },

  merchantField: {
    flex: 1,
    marginBottom: '8px'
  },
  productName: {
    width: '100%',
    marginBottom: '8px'
  },

  donationContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '24px',
    padding: '8px',
    backgroundColor: 'ghostwhite',
    border: '2px solid grey',
    borderRadius: '4px'
  },
  removeDonationButton: {
    position: 'absolute',
    top: -12,
    right: -12,
    zIndex: 2,
    padding: '6px !important',
    minHeight: '0px',
    height: '24px !important',
    width: '24px !important',
    border: '1px solid grey !important',
    color: 'black !important',
    backgroundColor: 'ghostwhite !important'
  },
  donationAmount: {
    width: '100px',
    marginBottom: '8px'
  },
  donationSubtitle: {
    flex: 1,
    marginBottom: '8px'
  },
  donationContent: {
    minWidth: '300px',

    '& > div': {
      width: '100%'
    }
  },
  donationMinimumAmount: {
    minWidth: '200px',
    flex: 1
  },
  newDonationButton: {
    marginBottom: '20px'
  },

  buttonsContainer: {
    display: 'flex'
  },
  button: {
    margin: '12px'
  }
}));

/*
 * REFERENCE
 * https://next.material-ui.com/customization/themes/
 */

import { createTheme } from '@mui/material/styles';

/*
 * The default theme is created using this code:
  createTheme({
    palette: {
      primary: indigo,
      secondary: pink,
      error: red,
      // Used by `getContrastText()` to maximize the contrast between the background and
      // the text.
      contrastThreshold: 3,
      // Used to shift a color's luminance by approximately
      // two indexes within its tonal palette.
      // E.g., shift from Red 500 to Red 300 or Red 700.
      tonalOffset: 0.2,
    }
  })
 */

const SHADES = {
  grey: {
    900: '#1E202A',
    800: '#22242F',
    700: '#262935',
    600: '#3B3E49',
    500: '#51535D',
    400: '#676971',
    300: '#7C7E85',
    200: '#96979D',
    100: '#A8A9AE',
    50: '#BDBEC2'
  },
  primary: {
    900: '#5A767D', // pressed
    800: '#46666D', // focus
    700: '#1F454E', // hover
    500: '#0C3640', // main
    300: '#A3B3B7' // disabled
  },
  secondary: {
    900: '#8AC2C2', // pressed
    800: '#7EB1B1', // focus
    700: '#93D0D0', // hover
    500: '#9AD9D9', // main
    300: '#D9F1F1' // disabled
  },
  tertiary: {
    900: '#000000', // pressed
    800: '#000000', // focus
    700: '#000000', // hover
    500: '#287681', // main
    300: '#000000' // disabled
  },
  error: {
    500: '#891145',
    100: '#F9CDCE'
  }
};

const COLORS = {
  alabaster: '#E7EEEE',
  atollBlueGreen: '#9E9E9E',
  azureRadiance: '#087EE1',
  black: '#000000',
  darkNordic: '#0C3640',
  emerald: '#D15582',
  gainsBoro: '#E1E1E1',
  grey: '#9E9E9E',
  lightTeal: '#9AD9D9',
  linkWater: '#C9CACD',
  mischka: '#A8A9AE',
  roseFog: '#E7B2BC',
  sonicSilver: '#757575',
  white: '#FFFFFF',
  whiteSmoke: '#F2F2F2',
  militaryGreen: '#397C0C'
};

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 540,
      sm: 768,
      md: 959,
      lg: 1239
    }
  },
  palette: {
    primary: {
      dark: SHADES.primary[700],
      main: SHADES.primary[500],
      light: SHADES.primary[300],
      contrastText: COLORS.white,
      ...SHADES.primary
    },
    secondary: {
      dark: SHADES.secondary[700],
      main: SHADES.secondary[500],
      light: SHADES.secondary[300],
      contrastText: COLORS.white,
      ...SHADES.secondary
    },
    tertiary: {
      main: SHADES.tertiary[500]
    },
    grey: SHADES.grey,
    text: {
      primary: COLORS.black,
      secondary: COLORS.sonicSilver,
      onPrimary: {
        dark: 'rgba(255, 255, 255, 1)',
        main: 'rgba(255, 255, 255, 0.87)',
        light: 'rgba(255, 255, 255, 0.6)',
        disabled: 'rgba(255, 255, 255, 0.38)'
      },
      onSecondary: {
        dark: SHADES.primary[300],
        main: SHADES.primary[500],
        light: SHADES.primary[700]
      },
      onSurface: {
        dark: 'rgba(0, 0, 0, 1)',
        main: 'rgba(0, 0, 0, 0.87)',
        light: 'rgba(0, 0, 0, 0.6)',
        disabled: 'rgba(0, 0, 0, 0.38)'
      }
    },
    error: {
      main: SHADES.error[500],
      background: SHADES.error[100]
    },
    border: {
      divider: COLORS.whiteSmoke,
      main: COLORS.gainsBoro
    },
    action: {
      disabledBackground: COLORS.mischka,
      disabledText: COLORS.linkWater,
      check: COLORS.militaryGreen,
      expanded: COLORS.alabaster
    },
    background: {
      hover: 'rgba(0, 0, 0, 0.04)',
      pressed: 'rgba(0, 0, 0, 0.10)',
      focus: 'rgba(0, 0, 0, 0.18)'
    },
    type: 'light'
  },
  typography: {
    fontFamily: [
      '"Montserrat"',
      '"Open Sans"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Roboto"',
      '"Oxygen"',
      '"Ubuntu"',
      '"Cantarell"',
      '"Fira Sans"',
      '"Droid Sans"',
      '"Helvetica Neue"',
      'sans-serif'
    ].join(',')
  },
  props: {
    MuiTypography: {
      variantMapping: {
        body1: 'span'
      }
    }
  }
});

export default theme;

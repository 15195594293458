/* eslint-disable import/prefer-default-export */
import { get, post } from 'wtb-utils';

const getMerchantData = (id) => get(`${process.env.REACT_APP_API_URL}/${id}`);

const generateCheckout = (id, payment) => post(`${process.env.REACT_APP_API_URL}/${id}`, payment);

export {
  getMerchantData,
  generateCheckout
};
